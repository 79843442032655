<!--团购兑换订单-->
<template>
  <div class="orderList">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="请输入昵称 / 手机号搜索"
          @input="kwChange"
          clearable
          class="mr15"
        />

        <el-select
          v-model="tableParams.orderStatus"
          placeholder="全部状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(onlineOrderStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-input
          v-model="tableParams.redemptionCode"
          placeholder="请输入兑换码搜索"
          @input="kwChange"
          clearable
          class="mr15"
        />
      </div>
      <el-date-picker
        v-model="dateToDate"
        type="daterange"
        align="center"
        unlink-panels
        range-separator="~"
        start-placeholder="兑换时间"
        end-placeholder="兑换时间"
        :picker-options="pickerOptions"
        @change="reload"
        clearable
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="团购券名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.buyGoodsName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兑换时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兑换码" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.redemptionCode || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" min-width="100">
        <template slot-scope="scope">
          <span>{{ onlineOrderStatusObj[scope.row.orderStatus] || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券类型" align="center" min-width="140">
        <template slot-scope="scope">
          <span>{{ cardTypeObj[scope.row.cardType] || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对应系统卡券名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name || "-" }}</span>
          <span>丨</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="120">
        <template slot-scope="scope" v-if="scope.row.orderStatus == 2">
          <el-button type="text" size="medium" @click.stop="onInfo(scope.row)"
            >退款</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
  </div>
</template>
<script>
import { pickerOptions, onlineOrderStatusObj, cardTypeObj } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";

import { getOrderListByThird, goRefundByThird } from "@/api/group/shop.js";
export default {
  data() {
    return {
      ObjToOpt,
      onlineOrderStatusObj,
      pickerOptions,
      cardTypeObj,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        orderStatus: "", //订单状态
        redemptionCode: "", // 兑换码
        dateFrom: "", // 开始时间
        dateTo: "", // 结束时间
      },
      currentPage: 1,
      dateToDate: [], //日期
      orderId:''
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getOrderListByThird();
  },
  methods: {
    // 退款
    goRefundByThird() {
      let params = {
        orderId:this.orderId
      };
      goRefundByThird(params).then((res) => {
        this.$message({
          type: "success",
          message: "退款成功!",
        });
        this.reload();
      });
    },
    // 【监听】详情
    onInfo(row) {
      this.orderId =row.orderId
      this.$confirm("请确定是否退款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.goRefundByThird();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退款",
          });
        });
    },
    // 团购兑换订单
    getOrderListByThird() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let params = {
        page: this.tableParams.page,
        size: this.tableParams.size,
        kw: this.tableParams.kw,
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        orderStatus: this.tableParams.orderStatus,
        redemptionCode: this.tableParams.redemptionCode,
        startTime: this.tableParams.dateFrom,
        endTime: this.tableParams.dateTo,
      };
      getOrderListByThird(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOrderListByThird();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOrderListByThird();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.orderList {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    margin-bottom: 0.15rem;
  }

  .search-L {
    display: flex;
  }

  .search-R {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>